.horizontal-post__container {
	display: flex;
	width: 100%;
	height: 100%;
	justify-content: space-evenly;
}

.hp-img {
	width: 400px;
	height: 400px;
	aspect-ratio: 1 1;
	border-radius: 20px;
}

.hp-slogan-container {
    margin:  2rem 0 2rem 2rem;
	display: flex;
	flex-direction: column;
}

.hp-tagline {
	font-family: 'Poppins';
	font-size: 2rem;
	font-weight: 600;
	text-align: left;
}

.hp-sub-tagline {
    margin-bottom: 1rem;
}

.hp-slogan-text {
	font-family: 'Poppins';
	font-size: 1rem;
	font-weight: 500;
	text-align: left;
    margin-bottom: 1rem;
}

.hp-section-btn {
	background: #14212b;
	color: #fff;
	height: 60px;
	width: 232px;
	border-radius: 15px;
	border: 0;
	font-family: 'Poppins';
	font-size: 18px;
	font-weight: 700;
	letter-spacing: 0em;
	text-align: center;
}

.hp-section-btn:hover {
	transform: scale(1.04);
	transition: 0.2s ease-in-out;
	cursor: pointer;
}

@media screen and (max-width: 1200px) {
	.horizontal-post__container {
		flex-direction: column !important;
		height: fit-content;
		align-items: center;
	}

	.hp-slogan-container {
		align-items: center;
        margin: 2rem 0 !important;
	}

	.hp-slogan-container > * {
		text-align: center;
	}

    .hp-tagline {
        font-size: 1.6rem;
    }
	.gq-sec-3 .home-common-wrap{
		width: initial !important;
		padding: 0 !important;
	}
	.hp-slogan-text {
		width: 600px;
	}
}

@media screen and (max-width: 600px) {
	.hp-slogan-text {
		width: initial;
	}
    .hp-img {
		width: 300px;
		height: 300px;
	}
}
