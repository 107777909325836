.cu-sec-4 {
    display: flex;
    height: 100%;
    padding: 75px 100px;
}

@media screen and (max-width:768px) {
    .cu-sec-4 {
        padding: 0;
    }
}