.post-container {
	position: relative;
	display: flex;
	flex-direction: column;
	max-width: 500px;
	box-shadow: 0px 3.10687px 61.3607px -16.3111px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	margin: 0;
	min-height: 100%;
	background-color: #fff;
}

/* img */
.post-img-container {
	width: 100%;
	height: 250px;
}

.post-img {
	width: 100%;
	border-top-left-radius: 20px;
	border-top-right-radius: 20px;
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	overflow: hidden;
}

.post-text-container {
	display: flex;
	flex-direction: column;
	padding: 2rem 1.6rem;
	vertical-align: baseline;

}

.post-heading {
	color: #333333;
	font-family: 'Poppins';
	font-size: 19.5px;
	font-weight: 700;
	line-height: 30px;
	letter-spacing: 0em;
	text-align: left;
}

.post-price {
	color: #e94e1b;
	font-family: 'Poppins';
	font-size: 25px;
	font-weight: 700;
	line-height: 37px;
	letter-spacing: 0em;
	text-align: left;
}

/* btn */
.post-btn-container {
	display: flex;
	margin: 1rem 0;
	justify-content: center;
	height: fit-content;
}

.post-btn {
	background: #14212b;
	color: #fff;
	height: 60px;
	width: 232px;
	font-family: 'Poppins';
	font-size: 1.2rem;
	font-weight: 700;
	text-align: center;
	border-radius: 15px;
	box-shadow: 5px 20px 44px -10px #33333330;
	cursor: pointer;
}

.post-btn:hover {
	transform: scale(1.04);
	transition: 0.2s ease-in-out;
}

@media all and (max-width: 1250px) {
	.post-container {
		width: 100%;
		margin: 20px 0;
	}
	.post-img {
		width: 100%;
	}
	#post-1,
	#post-2,
	#post-3,
	#post-4 {
		margin: 10px 0;
	}
}

@media all and (max-width: 600px) {
	.post-container {
		height: 200px;
		max-height: 400px;
		flex-direction: row;
		margin: 1rem 0;
	}
	.post-img-container {
		height: 200px;
		max-height: 400px;
		flex-basis: 100%;
		display: flex;
		vertical-align: middle;
	}
	.post-img {
		border-radius: 0;
		border-top-left-radius: 15px;
		border-bottom-left-radius: 15px;
		height: 100%;
	}
	.post-text-container {
		flex-basis: 70%;
		padding: 5px 10px;
		text-align: center;
	}
	.post-heading,
	.post-price {
		font-size: 1.2rem;
		text-align: center;
		height: fit-content;
		letter-spacing: 0.6px;
	}
	.post-text-container > p {
		display: none;
	}

	.post-btn-container {
		margin: 0;
	}
	.post-btn {
		width: 100px;
		font-size: 0.6rem;
	}
}
