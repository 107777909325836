@import url(../Section4-5.css);

/* section5 */
.section-5 .slogan-container {
    padding: 0 3rem 0 0;
} 

.section-5 .tagline,
.section-5 .slogan-text {
    color: #fff;
}

.section-5-btn {
    background-color: #14212B;
} 

@media screen and (max-width: 1100px) {
    .section-5 {
        flex-direction: column-reverse;
    }
    .section-5 .sub-tagline {
        color: #282828;
    }
}