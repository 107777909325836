/* Form */
.contact-form__container {
	width: 440px;
}

.contact-form {
	width: 100%;
}

.contact-form__input {
	width: 100%;
	height: 64px;
	color: #000;
	background-color: #eee;
	border: 0;
	border-radius: 15px;
	font-family: 'Poppins';
	font-size: 1rem;
	font-weight: 600;
	line-height: 24px;
	text-align: center;
	margin: 5px 0;
}

.contact-form-message__input {
	height: 128px;
}

.contact-form__btn {
	width: 100%;
	height: 64px;
	border-radius: 15px;
	background: #14212b;
	color: #fff;
	font-family: 'Poppins';
	font-size: 1rem;
	font-weight: 600;
	line-height: 24px;
	text-align: center;
}

/* Social icons */
.contact-form__social-icons-container {
	display: flex;
	justify-content: center;
	margin: 2rem 0;
	height: 50px;
}

.contact-form__social-icon-container {
    background: #E94E1B;
    margin: 0 0.5rem;
    height: 100%;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}
.contact-form__social-icon {
    font-size: 1.75rem;
	color: #fff;
}

@media screen and (max-width: 768px) {
}
@media screen and (max-width: 600px) {
	.contact-form__container {
		width: 260px;
	}
	.contact-form {
		width: 100%;
	}
	.contact-form__input {
		height: 50px;
		margin: 4px 0;
		font-size: 0.8rem;
	}
	.contact-form-message__input {
		height: 90px;
		vertical-align: top;
	}

	.contact-form__social-icon-container {
        margin: 0 0.35rem;
	}
}
