@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@800&display=swap');

@media screen and (max-width: 500px) {
	.logo-image {
		transform: scale(1.6) !important;
		margin-left: 1rem !important;
	}
	.navbar-burger {
		height: 2rem !important;
		width: 2rem !important;
	}
}

:root {
	--color: #e94e1b;
}

.navbar {
	height: fit-content !important;
}

/* brand */
.logo-image {
	transform: scale(2);
}

/* menu */
.navbar-item a {
	font-family: 'Poppins', sans-serif;
	color: white;
	font-weight: 800;
}

.navbar-item:hover,
.navbar-item:active,
.navbar-item:focus {
	color: var(--color) !important;
	background-color: transparent !important;
}

.contact-btn {
	width: 10rem;
	height: 2.5rem;
	background-color: var(--color);
	color: #fff;
	font-family: 'Poppins', sans-serif;
	font-weight: 800;
	border-radius: 10px;
	border: none;
	cursor: pointer;
}

.contact-btn:hover {
	transform: scale(1.04);
	transition: 0.2s ease-in-out;
}

.navbar-burger span {
	color: #fff;
}

.navbar-burger {
	align-self: center;
	width: 40px !important;
	height: 40px !important;
}

#nav-links {
	background-color: #0a0a0a;
	border-radius: 10px;
}

.navbar-menu {
	animation: navAnimOpen 0.5s ease-in-out;
}

@keyframes navAnimOpen {
	0% {
		display: none;
		opacity: 0;
		max-height: 0;
	}
	1% {
		display: block;
		opacity: 0;
	}
	100% {
		opacity: 1;
		max-height: 396px;
	}
}

/* Latest Update */

.navbar-wrap {
	width: 100vw;
	background-color: #0A0A0A;
	height: 80px;
	position: fixed;
	top: 0;
	z-index: 1000;
}

@media screen and (min-width: 768px) {
	.contact-btn-wrap {
		padding-right: 0 !important;
	}
}