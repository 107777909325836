.get-quote-main {
	background-color: #f9f9f9;
}

.gq-section {
	padding: 50px 100px;
	height: fit-content;
}

.gq-third-section {
	padding: 0;
	width: 100%;
	height: fit-content;
	background-color: #e94e1b;
}
.gq-fourht-section {
	padding-bottom: 100px;
}

/* Tablet */
@media screen and (max-width: 1200px) {
	.gq-section {
		padding: 50px 50px;
	}
	.gq-fourht-section {
		padding-bottom: 100px;
	}
}

/* Mobile */
@media screen and (max-width: 600px) {
	.gq-section {
		padding: 16px 24px;
	}
    .gq-fourht-section {
		padding-bottom: 100px;
	}
}
