.modal-overlay {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0,0.7);
    z-index: 1000;
}

.modal-container {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-55%,-50%);
    background-color: #fff;
    border-radius: 15px;
    width: 687px;
    height: 95vh;
    z-index: 1000;
    overflow: hidden;
}

@media screen and (max-width:1200px) {
    .modal-container {
        width: 95vw;
        height: 95vh;
        transform: translate(-50%,-50%);
        overflow-y: scroll;
    }
}
@media screen and (max-width:600px) {
}