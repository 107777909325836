.plane {
	width: 100vw;
	height: fit-content;
	display: flex;
	justify-content: center;
	align-items: center;
	vertical-align: middle;
	position: relative;
}

.plane svg {
	height: 100px;
}
.footer-plane-icon {
	position: absolute;
	left: 50;
	bottom: 0;
	transform: translateY(-50%);
}
.clipppp {
	background-color: #f9f9f9 !important;
	width: 100vw;
	height: 5rem;
	clip-path: polygon(100% 0, 100% 100%, 50% 23%, 0 100%, 0 0);
}

.clipppp-wrap {
	filter: drop-shadow(5px 6px 3px rgba(50, 50, 0, 0.5));
}

.footer-reactangle {
	width: 100vw;
}

.d-footer {
	width: 100vw;
	height: fit-content;
	display: flex;
	flex-direction: column;
	background-color: #fff !important;
}

.column-sections {
	display: flex;
	padding: 1rem;
	margin: 0;
	height: fit-content;
	background-color: #ffffff !important;
}

.column-section {
	flex-basis: 20%;
	height: fit-content;
	margin: 1rem 1rem;
	padding: 1rem;
}

.about-section {
	flex-basis: 30%;
	padding-bottom: 100px !important;
}

.about-paragraph li {
	text-align: left;
}

.about-paragraph {
	padding-left: 2rem;
}

.flight-master-logo {
	margin-bottom: 1rem;
}

.footer-section-heading {
	font-family: 'Poppins';
	font-size: 1.2rem;
	font-weight: 600;
	line-height: 27px;
	letter-spacing: 0em;
	text-align: left;
	color: #14212b;
	margin-bottom: 1rem;
}

.footer-menu {
	color: #7d8292;
	font-family: Poppins;
	font-size: 12px;
	line-height: 31px;
	letter-spacing: 0em;
	text-align: left;
}

.contact-section i {
	margin-right: 6px;
	font-size: 1rem;
	color: #e94e1b;
}

.map {
	margin: 1rem 0;
	border-radius: 15px;
}

.bottom-sections {
	width: 100%;
	height: 50px;
	display: flex;
	flex-basis: 50%;
	padding: 1rem 3rem;
	margin: 0 3rem;
	vertical-align: middle;
}

.copy-right {
	flex-basis: 76%;
	font-family: 'Poppins';
	font-size: 0.8rem;
	line-height: 22px;
	letter-spacing: 0em;
	text-align: left;
}

.social-links {
	flex-basis: 20%;
	align-self: flex-end;
	display: flex;
	justify-content: flex-start;
	padding: 0 auto;
}
.social-links i {
	font-size: 1.5rem;
	margin: 0 0.6rem;
	color: #1d263d;
}

@media screen and (max-width: 1200px) {
	.column-sections {
		flex-direction: column;
	}
	.column-section {
		margin: 1rem 0;
	}
	.bottom-sections {
		flex-direction: column-reverse;
		align-items: center;
		margin: 1rem 0;
		padding: 1rem;
	}
	.copy-right span {
		font-size: 0.75rem;
		display: none;
	}
	.copy-right::after {
		content: '© Copyright 2022 Flight Master. All Rights Reserved.';
	}
	.bottom-section {
		align-self: center;
	}
	.footer-plane-icon {
		transform: translateY(-25%);
	}
	.footer-plane-icon {
		left: 50;
		bottom: 0;
		transform: translateY(-50%);
	}
	/* .plane {
		
	} */
}

@media screen and (max-width: 600px) {
	.footer-plane-icon {
		transform: translateY(-50%);
	}
}
