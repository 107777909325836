@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

.d-post-container {
	display: flex;
	border-radius: 15px;
	background-color: #fff;
	border-radius: 15px;
	box-shadow: 0px 4px 79px -21px rgba(143, 143, 143, 0.35);
	height: 100%;
	align-items: center;
	margin: 0.25rem 0;
}

#d-post-container-1,
#d-post-container-3 {
	margin-right: 1rem;
}

#d-post-container-2,
#d-post-container-4 {
	margin-left: 1rem;
}

/* img */
.d-img-container {
	flex-basis: 40%;
	width: fit-content;
	height: fit-content;
}

.d-img-container img {
	border-radius: 15px;
	margin: 1rem 0 1rem 1rem;
	width: 220px;
	height: 200px;
}

/* text */
.d-text-container {
	flex-basis: 60%;
	margin: 2rem 2rem 2rem 0;
	height: fit-content;
}

.d-heading {
	font-family: 'Poppins';
	font-size: 20px;
	font-weight: 700;
	line-height: 30px;
	text-align: left;
	margin: 0.5rem 0;
}

.d-paragraph {
	font-family: 'Roboto', sans-serif;
	font-size: 1rem;
	font-weight: 400;
	text-align: left;
	line-height: 24px;
	margin: 0 0 1rem 0;
}

.d-post-link {
	display: flex;
	align-items: center;
}

.d-post-link span {
	font-family: 'Poppins';
	font-size: 1rem;
	font-weight: 500;
	line-height: 30px;
	text-align: left;
	text-decoration: none;
	color: #14212b;
}

.d-post-link span:hover {
	color: #e94e1b;
	transition: 0.2s ease;
}

.d-post-link i {
	width: 1rem;
	height: 1rem;
	border-radius: 16px;
	display: flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	color: #fff;
	font-size: 0.6rem;
	margin: 0 0.2rem;
	background-color: #e94e1b;
}

@media screen and (max-width: 1572px) {
	#d-post-container-1,
	#d-post-container-3 {
		margin-right: 0;
	}

	#d-post-container-2,
	#d-post-container-4 {
		margin-left: 0;
	}
	.d-post-container {
		margin: 10px 0;
	}
	.d-text-container {
		margin-left: 25px;
	}
}

@media all and (max-width: 768px) {
	.d-post-container {
		margin: 0.25rem 0;
	}
	.d-img-container img {
		width: 150px;
		height: 150px;
	}
}

@media all and (max-width: 572px) {
	.d-post-container {
		margin: 1rem 0;
		height: fit-content;
		width: fit-content;
	}
	.d-text-container {
		margin: 0.75rem;
		flex-basis: 100%;
		display: flex;
		flex-direction: column;
	}
	.d-img-container {
		flex-basis: 60%;
		display: flex;
		align-items: center;
	}
	.d-img-container img {
		margin: 5px;
		width: 120px;
		height: 120px;
	}
	.d-heading,
	.d-paragraph,
	.d-post-link span {
		margin: 0;
		margin-bottom: 5px 0;
	}

	.d-heading {
		font-size: 1rem;
	}

	.d-paragraph {
		font-size: 0.75rem;
		line-height: 18px;
	}
	.d-post-link span {
		font-size: 0.75rem;
	}
	#d-post-container-1,
	#d-post-container-3 {
		margin-right: 0;
	}

	#d-post-container-2,
	#d-post-container-4 {
		margin-left: 0;
	}
}
