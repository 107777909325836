.feature-container {
	display: flex;
	flex-direction: column;
	flex-basis: 25%;
	margin-bottom: 5px;
	box-shadow: 0px 3.10687px 61.3607px -16.3111px rgba(0, 0, 0, 0.25);
	border-radius: 20px;
	padding: 2rem;
	display: flex;
	flex-direction: column;
	background-color: #fff;
	max-width: 600px;
	margin: 0;
}

.feature-icon-container {
	flex-basis: 30%;
	min-width: 65px;
	max-width: 65px;
	min-height: 65px;
	max-height: 65px;
	border-radius: 22.5818px;
	background: #e94e1b;
	box-shadow: 13.5491px 31.6145px 59.616px 33.4211px rgba(233, 78, 27, 0.1);
	display: flex;
	align-items: center;
	justify-content: center;
	vertical-align: middle;
	margin-bottom: 0.8rem;
}
.feature-icon-container img {
	min-height: 70%;
}
.feature-heading {
	color: #333333;
	flex-basis: 10%;
	font-family: 'Poppins';
	font-size: 20px;
	font-weight: 700;
	line-height: 30px;
	letter-spacing: 0em;
	text-align: left;
}

.feature-paragraph {
	flex-basis: 40%;
	font-family: 'Roboto';
}

.feature-link {
	text-decoration: none;
	color: #7d8292;
	margin-top: 1rem;
	flex-basis: 5%;
	transition: 0.2s ease-in-out;
}

@media screen and (max-width: 1200px) {
	.feature-container {
		margin: 10px 0;
		min-width: 448px;
		max-width: 448px;
		padding: 1.25rem 1.6rem;
		height: fit-content;
	}
	.feature-icon-container {
		margin-bottom: 5px;
		transform: scale(0.8);
	}
	.feature-link {
		margin-top: 2px;
	}
}

@media screen and (max-width: 600px) {
	.feature-container {
		margin: 10px 0;
		width: 335px;
		padding: 1.25rem 1.6rem;
		min-width: 100%;
		max-width: 100%;
	}
	.feature-icon-container {
		margin-bottom: 5px;
		transform: scale(0.8);
	}
	.feature-link {
		margin-top: 2px;
	}
}
