.topic-text__container {
	height: fit-content;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin: 1.5rem 0;
}

.topic-text__section-heading {
	font-family: 'Poppins';
	font-style: normal;
	font-size: 26px;
	font-stretch: expanded;
	font-weight: 800;
	margin-bottom: 0;
	text-align: center;
	text-shadow: 5px 15px 20px rgba(116, 116, 116, 0.06);
}

.topic-text__heading-icon {
	height: 9px;
	width: 40px;
	border-radius: 5px;
	background: #e94e1b;
	margin: 0.2rem auto 2rem auto;
}

.topic-text__paragraph {
	font-family: 'ABeeZee', sans-serif;
	font-size: 16px;
	margin: 1rem 100px 2rem 100px;
	height: fit-content;
	font-weight: 400;
	line-height: 25px;
	text-align: center;
}

@media screen and (max-width: 1400px) {
	.topic-text__section-heading {
		width: 100%;
	}
	.topic-text__paragraph {
		margin: 1rem 0 2rem 0;
		width: 600px ;
	}
	
}
@media screen and (max-width: 600px) {
	.topic-text__section-heading {
		width: 100%;
	}
	.topic-text__paragraph {
		width: 100% ;
		padding: 0;
	}

}