.sec-1 {
	overflow: hidden;
	background: linear-gradient(#f37a50, #e94e1b);
}

.welcome-banner {
	width: 100vw;
	height: fit-content;
	padding: 0 100px;
	position: relative;
	display: flex;
}

.wave {
	position: absolute;
	z-index: 10;
	bottom: 0;
	min-width: 100%;
	height: 180px;
}

/* Slider */
.slider-wrap {
	width: 100%;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
}

.slide {
	width: 80%;
	height: 600px;
}

.sec-1-slide-wave {
	z-index: 10;
	width: 110vw;
}
.sec-1-slide-wave-path {
	background-color: #fff !important;
}
.slide-btn i {
	margin: 0 2rem;
	color: #fff;
	transform: scale(2);
	border: #fff 2px solid;
	padding: 0.1rem 0.3rem;
	border-radius: 5px;
}

.slide-bottom__bar {
	width: 100%;
	height: 100px;
	background-color: #fff;
}

.swiper-button-next {
	color: white !important;
	width: 53.57px !important;
	height: 53.57px !important;
	scale: 0.8;
	border: 5px #fff solid;
	padding: 1.75rem 1.5rem;
	border-radius: 15px;
}

.swiper-button-prev {
	color: white !important;
	width: 53.57px !important;
	height: 53.57px !important;
	scale: 0.8;
	border: 5px #fff solid;
	padding: 1.75rem 1.5rem;
	border-radius: 15px;
}

@media screen and (max-width: 1572px) {
	.welcome-banner {
		width: 95vw;
		padding: 0 30px;
	}
	.home-common-wrap {
		width: 100%;
	}
}

@media screen and (max-width: 1200px) {
	.sec-1 {
		height: 50vh;
	}
	.welcome-banner {
		height: 100%;
	}
	.swiper-button-prev,
	.swiper-button-next {
		margin: 0;
		border: none;
		padding: 0;
	}
	.swiper-button-prev,
	.swiper-button-next {
		top: 300px !important;
	}
}
@media screen and (max-width: 1000px) and (min-width: 760px) {
	.sec-1 {
		height: 550px;
	}
}
@media screen and (max-width: 600px) {
	.sec-1 {
		height: 500px;
	}
	.wave {
		height: 30px;
	}
	.welcome-banner {
		padding: 20px;
		height: 500px;
	}
	.sec-1 .home-common-wrap {
		height: 100%;
		width: 100% !important;
	}
}
