.page-header-section {
	display: flex;
	height: 275px;
	width: 100%;
	padding: 100px;
	background: linear-gradient(90.01deg, #f37a50 -0.89%, rgba(233, 78, 27, 0.92) 99.99%) ,  url(../../img/GetQuote/GetQuoteHeader.png) local;
	background-position: right;
	background-size: 100vw;
	justify-content: flex-start;
	align-items: center;
	border-bottom-left-radius: 20px;
	border-bottom-right-radius: 20px;
}

.page-header-section-overlay {
}

.page-header-text {
	color: #fff;
	height: 56px;
	font-family: 'Poppins';
	font-size: 48px;
	font-weight: 700;
	text-align: left;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

/* Tablet */
@media screen and (max-width: 768px) {
	.page-header-section {
		height: 200px;
		padding: 20px 0 0 20px;
	}
	.page-header-text {
		font-size: 2.4rem;
	}
}

/* Mobile */
@media screen and (max-width: 600px) {
	.page-header-section {
		height: 150px;
		padding: 20px 0 0 20px;
	}
	.page-header-text {
		font-size: 1.5rem;
	}
}
