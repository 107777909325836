.slide-wrap {
	width: 100vw;
	height: 839px ;
	padding: 0 100px !important;
	margin: 0 100px;
}

.main-slide-container {
	width: 100vw;
	padding-top: 3rem;
}

#wave {
	position: absolute;
	bottom: 0;
	left: 0;
	transform: translateY(10%);
}

.text-container {
	width: 50vw;
	height: fit-content;
	/* border: 1px #000 solid; */
	left: 110px;
	top: 150px;
	position: absolute;
}

.main-column {
	align-self: center;
}

.welcome-text {
	font-family: 'Poppins';
	font-size: 48px;
	font-weight: 300;
	line-height: 72px;
	color: #ffffff;
}

.brand-name {
	font-family: 'Poppins';
	font-size: 88px;
	font-weight: 700;
	line-height: 102px;
	letter-spacing: -0.02em;
	text-align: left;
	color: #ffffff;
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
	margin-bottom: 1rem;
}

.slide-pic {
	position: absolute;
	right: 10px !important;
	bottom: 0 !important;
}

.text-paragraph {
	font-family: 'Poppins';
	font-size: 18px;
	font-weight: 400;
	line-height: 27px;
	letter-spacing: 0em;
	color: #fff;
	text-align: left;
	width: 465px;
}

.btn {
	height: 3rem;
	width: 12rem;
	padding: 0.5rem;
	border-radius: 10px;
	margin: 1rem 0;
	font-family: 'Poppins';
	font-weight: 600;
	color: #14212b;
	background-color: #fff;
	border: none;
	font-size: 1rem;
	box-shadow: 5px 20px 44px -10px rgba(51, 51, 51, 0.19);
}

.btn:hover {
	transform: scale(1.04);
	transition: 0.2s ease-in-out;
}

/* Image */
@media screen and (max-width: 1200px) {
	.slide-wrap {
		height: 850px;		
	}
	.text-container {
		width: fit-content;
		height: 10vh;
		left: 70px;
		top: 100px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		line-height: initial;
		text-align: center;
	}
	.welcome-text {
		font-size: 30px;
		font-weight: 300;
		line-height: initial;
	}

	.brand-name {
		font-size: 40px;
		line-height: initial;
		margin-bottom: 0;
	}

	.text-paragraph {
		font-size: 16px;
		text-align: center;

	}

	.btn {
		height: 1.5rem;
		width: 6rem;
		padding: 0.5rem;
		font-size: 0.75rem;
	}

	.slide-pic {
		width: 400px;
		transform: scale(0.61);
	}
	
	.wave {
		height: 100px;
	}
}
@media screen and (max-width: 1000px) and (min-width: 760px) {
	.slide-pic {
		width: 400px;
		scale: 0.6;
		bottom:  120px !important;
	}
	.slogan-item {
		text-align: center;
	}
	.text-paragraph {
		text-align: center;
		align-self: center;
		justify-self: center;
	}
}


@media screen and (max-width: 600px) {
	.text-container {
		width: fit-content;
		left: 24%;
		top: 100px;
	}
	.welcome-text {
		font-size: 20px;
		font-weight: 300;
		line-height: initial;
	}
	.brand-name {
		font-size: 30px;
		line-height: initial;
		margin-bottom: 0;
	}
	.text-paragraph {
		display: none;
	}
	.slide-pic {
	}
	.wave {
		height: 50px;
	}
	.slide-pic-new {
		height: 15rem;
		position: absolute;
		bottom: 0;
		right: 0;
	}
	.slide-wrap {
		display: flex;
		flex-direction: column;
		height: 680px;
	}
}

@media screen and (max-width: 380px) {
	.slide-wrap {
		height: 600px;
	}
	.text-container {
		left: 20%;
		top: 100px;
	}
}