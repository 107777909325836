.gq-sec-2__form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


@media screen and (max-width:768px) {
    
}

@media screen and (max-width:600px) {
}