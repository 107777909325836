.posts-container {
	height: fit-content;
	margin: 2rem 0;
	display: flex;
	justify-content: space-between !important;
}

#post-1 {
	margin-right: 10px;
}
#post-2,
#post-3 {
	margin: 0 10px;
}

#post-4 {
	margin-left: 10px;
}

@media all and (max-width: 1250px) {
	.posts-container {
		flex-direction: column;
		height: fit-content;
		align-items: center;
	}
}

@media all and (max-width: 472px) {
	.posts-container {
		margin: 1rem 0;
	}
}