.gq-sec-3 {
    display: flex;
    height: 100%;
    padding: 75px 100px;
}

@media screen and (max-width:1200px) {
    .gq-sec-3 {
        padding: 0;
    }
}