.section-7 {
	background-color: #14212b;
	border-radius: 15px;
	width: 100%;
	margin-bottom: 7rem !important;
	height: 177px;
	padding: 2rem;
	display: flex;
	justify-content: space-evenly;
	align-items: center;
}

.contact-taglines {
	flex-basis: 50%;
}

.section-7 .tagline,
.section-7 .tagline-text {
	color: #fff;
}

.tagline {
	font-family: 'Poppins';
	font-size: 1.5rem;
	font-weight: 600;
	line-height: 40px;
	text-align: left;
}

.tagline-text {
	font-family: 'Poppins';
	font-size: 0.8rem;
	text-align: left;
}

.phone-number {
	flex-basis: 30%;
	height: 70px;
	max-width: 290px;
	border-radius: 16px;
	background: #e94e1b;
	display: flex;
	align-items: center;
}

.phone-number:hover {
	transform: scale(1.04);
	transition: 0.2s ease-in-out;
}

.phone-number i {
	flex-basis: 20%;
	font-size: 2.5rem;
	margin: 1.5rem 0 1.5rem 1.5rem;
	color: #fff;
}

.p-number a {
	font-size: 1.6rem;
	color: #fff;
	font-weight: 800;
}

@media screen and (max-width: 1250px) {
	.section-7 {
		flex-direction: column;
		height: fit-content;
		margin: 0 !important;
	}
	.tagline-text {
		text-align: center;
	}
	.tagline {
		font-size: 1.2rem;
		font-weight: 500;
	}
	.phone-number {
		width: fit-content;
		height: fit-content;
		max-width: 550px;
		min-width: 300px;
		margin-top: 1rem;
		align-items: center;
		justify-content: center;
		padding: 0.5rem 1rem !important;
	}
	.phone-number i {
		font-size: 1.6rem;
		margin: 0.5rem 0;
		margin-left: 1rem;
	}
	.p-number {
		font-size: 1.2rem !important;
	}
}

@media screen and (max-width: 600px) {
	.tagline {
		text-align: center;
	}
	.phone-number {
		max-width: 550px;
		min-width: 280px;
	}
}
