.popup-bottom-input__container {
	display: flex;
	width: 450px;
	height: 100%;
	vertical-align: middle;
	align-items: center;
	justify-content: left;
}

.popup-bottom-input__img {
	width: 25px;
	height: 25px;
	margin-right: 1rem;
}

.popup-bottom-input__border-text {
	position: absolute;
	z-index: 1;
	top: -20%;
	left: 10%;
	font-size: 14px;
	font-family: 'Poppins';
	font-weight: 600;
	line-height: 21px;
	text-align: left;
	background-color: transparent;
}

.popup-bottom-input__wrap {
	position: relative;
	flex-basis: 82%;
	width: 350px;
	height: 48px;
	margin-left: 1rem;
}

.popup-bottom-input__input {
	border: 0;
	background-color: #e5e5e5;
	border-radius: 10px;
	padding-left: 1.5rem;
	height: 100%;
	width: 100%;
	font-family: Poppins;
	font-size: 1rem;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
}

.popup-bottom-input__input:focus,
.popup-bottom-input__input:active {
	outline: none;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
    display: none;
    -webkit-appearance: none;
}

@media screen and (max-width: 1200px) {
	.popup-bottom-input__wrap {
		width: 50px !important;
	}

	.popup-bottom-input__container {
		width: 400px;
	}

	.popup-bottom-input__img {
		margin-right: 0.5rem;
		margin-left: 0.5rem;
	}

	.popup-bottom-input__border-text {
		font-size: 0.875rem;
	}

	.popup-bottom-input__wrap {
		height: 3rem;
		margin-left: 1rem;
	}
}

@media screen and (max-width: 600px) {
	.popup-bottom-input__img {
		flex-basis: 10%;
		width: 20px;
		height: 20px;
		margin-right: 0.5rem;
		margin-right: 0.5rem;
	}
	.popup-bottom-input__wrap {
		width: 150px !important;
		height: 35px;
		margin-left: 0;
	}
	.popup-bottom-input__input {
		padding: 5px;
		font-size: 0.75rem;
		width: 100%;
	}
	.popup-bottom-input__input [type='date'] {
		width: 100%;
	}
	.popup-bottom-input__border-text {
		font-size: 0.75rem;
		background-color: transparent;
	}
}

