/* @import url('http://fonts.cdnfonts.com/css/gilroy-bold'); */
@import url(//db.onlinewebfonts.com/c/1dc8ecd8056a5ea7aa7de1db42b5b639?family=Gilroy);
@import url('https://fonts.googleapis.com/css2?family=ABeeZee&display=swap');

.home-main {
	width: 100vw !important;
	background-color: #f9f9f9 !important;
}

.home-common-wrap {
	max-width: 1440px;
	margin: 0 auto;
}
/* Sections */
.item-container {
	display: flex;
	margin: 2rem 5rem;
}

.section {
	height: fit-content !important;
	padding-left: 100px !important;
	padding-right: 100px !important;
}
/* Fifth section */
.fifth-section {
	background-color: #e94e1b;
}

@media all and (max-width: 1572px) {
	.second-section {
		padding-left: 30px !important;
		padding-right: 30px !important;
	}
}

@media all and (max-width: 1200px) and (min-width: 650px) {
	.section {
		padding-top: 1rem !important;
		padding-bottom: 1rem !important;
	}
	.about-section {
		margin: 0;
		padding-top: 0;
	}
	.home-common-wrap {
		width: 560px !important;
		margin: 0 auto;
	}
}

@media all and (max-width: 600px) {
	.section {
		padding-left: 20px !important;
		padding-right: 20px !important;
		padding-top:10px !important;
		padding-bottom:  10px !important;
	
	}
	.home-common-wrap {
		width: initial !important;
		margin: 0 !important; 
	}
}
