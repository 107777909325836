/* trip type section */
.trip-type__radio-btns {
	display: flex;
	height: fit-content;
}

.trip-type__radio-btn {
	margin-right: 1rem;
}

.trip-type__radio-btn input {
	margin-right: 0.5rem;
}

.trip-type__radio-btn label {
	font-size: 1rem;
	font-weight: 700;
	line-height: 24px;
	text-align: left;
	color: #000;
}
/* Wanted */

@media screen and (max-width: 576px) {
}
