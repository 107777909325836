.contact-us-main {
    background-color: #E5E5E5;
}
.cu-section {
    padding: 50px 100px;
    height: fit-content;
}
.cu-fourth-section {
    padding: 0;
    width: 100%;
    height: fit-content;
    background-color: #E94E1B;
}
.cu-fifth-section {
    padding-bottom: 100px;
}
/* Tablet */
@media screen and (max-width:768px) {
    .cu-section {
        padding: 50px 50px;
    }
    .cu-fifth-section {
        padding-bottom: 100px;
    }
}

/* Mobile */
@media screen and (max-width:600px) {
    .cu-section {
        padding: 16px 24px;
    }
    .cu-fifth-section {
        padding-bottom: 100px;
    }
}