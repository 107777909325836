.features-container {
	height: fit-content;
	margin: 2rem 0;
	display: flex;
}

.features-container {
}
#feature-1 {
	margin-right: 10px;
}

#feature-2 {
	margin: 0 10px;
}
#feature-3 {
	margin: 0 10px;
}

#feature-4 {
	margin-left: 10px;
}

@media all and (max-width: 1200px) {
	.features-container {
		flex-direction: column;
		height: fit-content;
		align-items: center;
		margin: 1rem 0;
		width: 100%;
	}
	#feature-1 {
		margin-right: 0px;
		margin: 10px 0;
	}
	#feature-2 {
		margin: 0;
	}
	#feature-3 {
		margin: 0;
	}

	#feature-2,
	#feature-3 {
		margin: 10px 0;
	}

	#feature-4 {
		margin: 0;
		margin-left: 0;
	}
}
