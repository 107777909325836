.section-6-posts-container {
	width: 100%;
	height: 100%;
	min-height: 100%;
	max-height: 100%;
	padding: 1rem 0;
	display: flex;
	flex-direction: column;
}

.section-6-posts-row-container {
	flex-basis: 50%;
	margin: 0;
	display: flex;
	min-height: 100%;
	max-height: 100%;
	justify-content: space-between;
}

.section-6-post-container {
	min-height: 100%;
	max-height: 100%;
	width: 50%;
	margin: 0.2rem 0.25rem;
}

@media screen and (max-width: 1200px) {
	.section-6-posts-container {
		padding: 0;
	}
	.section-6-posts-row-container {
		flex-direction: column;
		margin: 0;
	}
	.section-6-post-container {
		width: 100%;
	}
}