.cu-sec-3__map-container {
	width: 100%;
	height: 496px;
}

.cu-sec-3__map {
	border-radius: 25px;
	width: 100%;
	height: 100%;
	filter: grayscale(100%);
}

@media screen and (max-width: 768px) {

}
@media screen and (max-width: 600px) {
    .cu-sec-3__map-container {
        height: 300px;
    }
    
}
