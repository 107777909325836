/*  */
/* @import url(../../Input/PassengerTypeInput/PassengerTypeInput.css); */
@import url(../../Input/PopupBottomInput/PopupBottomInput.css);
@import url(../../Input/TripTypeRadioInput/TripTypeRadioInput.css);
@import url(../../Input/TripTypeRadioInput/TripTypeRadioInput.css);
.popup-container {
	height: 100%;
	position: relative;
	flex-direction: column;
}

.popup-input-container {
	display: flex;
	margin: 0.5rem 0;
}

.popup-close-btn i {
	position: absolute;
	font-size: 30px;
	font-weight: 600;
	color: #fff;
	top: 20px;
	right: 20px;
}

/* Header */

.popup-header {
	display: flex;
	flex-direction: column;
	padding: 10px 100px;
	height: 25%;
	width: 100%;
	background: url(../../../img/Travel-Couple.png);
	background-size: cover;
	background-color: rgba(0, 0, 0, 0.8);
	box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.7);
}

.popup-header-h1-text {
	font-family: 'Poppins';
	font-size: 1rem;
	font-weight: 900;
	color: #fff;
	line-height: 36px;
}

.popup-header-h2-text {
	font-family: 'Roboto';
	font-size: 0.8rem;
	font-weight: 500;
	color: #fff;
}

.popup-header-h1-text_destination,
.popup-header-h2-text__price {
	color: #e94e1b;
}

/* Form */
.popup__form {
	padding: 0.2rem 50px;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 75%;
}

.popup__form * {
	font-family: 'Poppins';
	color: #000;
}

.form__tagline {
	font-family: 'Roboto';
	font-size: 18px;
	font-weight: 600;
	text-align: left;
	line-height: 27px;
	/* width: 446px; */
	border-radius: nullpx;
}

.popup__section {
	margin: 0;
	height: fit-content;
}

.popup__form-header {
	font-size: 0.9rem;
	font-weight: 600;
	margin: 0.2rem 0 !important;
	color: #e94e1b;
	margin-bottom: 20x;
}

/* trip type section */
.trip-type__radio-btns {
	display: flex;
	height: fit-content;
}

.trip-type__radio-btn {
	margin-right: 1rem;
}

.trip-type__radio-btn input {
	margin-right: 0.5rem;
}

.triptype-btn__label {
	font-size: 12px;
	font-weight: 700;
	text-align: left;
}

/* Flight class section */
.flight-classes__selector {
	height: 40px;
	width: 250px;
	border: 0;
	border-radius: 10px;
	padding: 10px;
	background: #f6f6f6
		url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>")
		no-repeat;
	background-position: calc(100% - 0.75rem) center !important;
	-moz-appearance: none !important;
	-webkit-appearance: none !important;
	appearance: none !important;
}

.flight-classes__selector-option {
	font-family: 'Poppins';
	font-size: 1rem;
	color: #666666;
}

.flight-class__header,
.passengers__header {
	font-size: 15px;
	font-weight: 700;
	line-height: 15px;
	text-align: left;
	color: #e94e1b;
	margin-bottom: 20px;
}

/* Passengers section */
.passengers__section {
	margin-bottom: 20px;
}

.popup-passengers__input-container,
.destination__input-container,
.contact-details__input-container {
	align-items: flex-start;
}

.popup-passengers__input-container {
	display: flex;
	margin-right: 1rem;
	margin-bottom: 15px;
}

.popup-passenger-type__container {
	flex-basis: 30%;
}
.passenger-type__header {
	height: 19px;
	width: 54px;
	border-radius: nullpx;
	font-family: Poppins;
	font-size: 15px;
	font-weight: 700;
	line-height: 15px;
	text-align: left;
}

.passenger-type__container {
	margin-right: 90px;
}

/* Destination section */
.destination__input-container {
	display: flex;
}

.popup-input__container {
	display: flex;
	width: 450px;
	height: 100%;
	vertical-align: middle;
	align-items: center;
	justify-content: left;
}

.popup-input__img {
	width: 25px;
	margin-right: 10px;
}

.popup-input__wrap {
	position: relative;
	height: 45px;
	width: 231px;
	border-radius: 10px;
}

.popup-input__input {
	border: 0;
	background-color: #e5e5e5;
	border-radius: 10px;
	padding-left: 1.5rem;
	height: 100%;
	width: 100%;
	font-family: 'Poppins';
	font-size: 1rem;
	font-weight: 400;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
}

.popup-input__border-text {
	position: absolute;
	z-index: 1;
	top: -20%;
	left: 10%;
	font-size: 14px;
	font-family: 'Poppins';
	font-weight: 600;
	line-height: 21px;
	text-align: left;
	background-color: transparent;
}

/* 	Dates Section */

.popup-dates__header {
	font-family: Poppins;
	font-size: 15px;
	font-weight: 700;
	line-height: 15px;
	text-align: left;
	color: #E94E1B;

}
/* Submit button */
.popup-submit-btn {
	height: 37.903507232667266px;
	width: 568.0000000000002px;
	font-family: 'Poppins';
	align-self: center;
	font-size: 1rem;
	font-weight: 600;
	border-radius: 10px;
	border: 0;
	color: #fff;
	background: #333;
	justify-self: flex-end;
	margin: 0.1rem 0;
}

@media screen and (max-width: 1200px) {
	.popup__section {
		height: fit-content;
		align-items: center;
		margin: 0.1rem 0;
	}
	.popup__form-header {
		margin: 0.1rem 0;
	}
	.popup-close-btn i {
		top: 10px;
		right: 15px;
		font-size: 20px;
	}
	.popup-header {
		padding: 15px 40px;
		justify-content: flex-end;
	}
	.popup-header-h1-text {
		font-size: 1rem;
		font-weight: 900;
		line-height: 28px;
	}
	.popup__form {
		padding: 15px 20px;
	}
	.popup-submit-btn {
		margin: 0.1rem 0;
		padding: 0.4rem 0;
		width: 97%;
		font-size: 1.2rem;
	}
	.popup-input-container {
		margin: 0.2rem 0;
	}
	.flight-classes__selector {
		height: 36px;
	}
}

@media screen and (max-width: 600px) {
	.popup-input__container {
	}
	.popup-input__wrap {
		width: 120px;
		height: 40px;
	}
	.popup-input__input {
		padding-left: 10px;
		font-size: 11px;
		
	}
	.flight-class__header {
		margin-bottom: 2px;
	}
	.passengers__header {
		margin-bottom: 5px;
	}
	.passenger-type__header {
		margin: 0 !important;
	}

}
