.d-contact-us-footer {
    background-color: #fff !important;
}

.clipppp-contact-us {
    background-color: #E5E5E5 !important;
	width: 100vw;
	height: 5rem;
	clip-path: polygon(100% 0, 100% 100%, 50% 23%, 0 100%, 0 0);
}

@media screen and (max-width: 600px) {
	.footer-plane-icon {
		transform: translateY(-50%);
	}
}
