/* image */
.common-section-containers {
	display: flex;
	padding: 4rem 0;
	margin: 0;
	vertical-align: middle;
	height: max-content;
	width: fit-content;
}

.img-container {
	flex-basis: 30%;
	width: 100%;
	height: 100%;
	border-radius: 21.1769px;
}

.img-container > img {
	border-radius: 21.1769px;
	height: 100%;
	min-height: 100%;
	width: 100%;
}

/* slogan */
.slogan-container {
	padding: 0 0 0 3rem;
	flex-basis: 70%;
	display: flex;
	flex-direction: column;
}

.tagline {
	flex-basis: 5%;
	font-family: 'Poppins';
	font-size: 2rem !important;
	font-weight: 700 !important;
	text-align: left;
	color: #333333;
	margin: 0;
	line-height: 53px;
	display: inline-block;	
}

.sub-tagline {
	color: #e94e1b;
}

.slogan-text {
	font-family: 'Poppins';
	font-size: 0.9rem;
	font-weight: 500;
	text-align: left;
	line-height: 24px;
	margin: 1rem 0 2rem 0;
	letter-spacing: 1.2px;
}

.section-btn {
	font-family: 'Poppins';
	font-size: 1.2rem;
	font-weight: 700;
	text-align: center;
	color: #fff;
	background: #e94e1b;
	height: 60px;
	width: 240px;
	border-radius: 15px;
	box-shadow: 5px 20px 44px -10px #33333330;
	border: none;
	cursor: pointer;
}

.section-btn:hover {
	transform: scale(1.04);
	transition: 0.2s ease-in-out;
}

@media screen and (max-width: 1200px) {
	.common-section-containers {
		flex-direction: column;
		align-items: center;
		padding: 1rem 0;
	}
	.img-container {
		width: 200px;
		height: 200px;
	}
	.slogan-container {
		padding: 10px 0 !important;
		display: flex;
		align-items: center;
		justify-content: center;
	}
	.tagline,
	.sub-tagline,
	.slogan-text {
		display: flex;
		align-items: center;
		justify-content: center;
		text-align: center;
		margin: auto;
	}

	.tagline {
		font-size: 1.5rem !important;
	}
	.section-btn {
		margin: 1rem 0;
	}
}
