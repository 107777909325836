.gq-form-container {
	width: 1000px;
	padding-left: 200px;
}

.gq-section-2 {
	margin-bottom: 2rem;
}
.gq-passengers__header {
	font-family: 'Poppins';
	font-size: 20px;
	font-weight: 700;
	line-height: 20px;
	text-align: left;
	color: #e94e1b;
}

.passengers__input-container {
	display: flex;
}

.gq-dates__header {
	font-family: 'Poppins';
	font-size: 1.25rem;
	font-weight: 700;
	line-height: 20px;
	text-align: left;
	margin: 2.5rem 0 1.5rem 0;
}

.trip-type__radio-btn-label {
	font-family: Poppins;
	font-size: 16px;
	font-weight: 700;
	line-height: 24px;
	letter-spacing: 0em;
	text-align: left;
}

.bottom-input__container {
	margin-top: 2.125rem;
}

.gq-message__box {
	width: 86.5%;
	height: 128px;
	border-radius: 10px;
	background: #e5e5e5;
	border: 0;
	padding: 1rem;
	font-family: 'Poppins';
	font-size: 1rem;
	font-weight: 400;
	line-height: 24px;
	text-align: left;
	margin-top: 1.5rem;
}
.gq-form__button {
	height: 50px;
	width: 150px;
	border-radius: 10px;
	background: #333333;
	color: #ffffff;
	font-family: 'Poppins';
	font-size: 1rem;
	font-weight: 700;
	line-height: 43px;
	margin: 2rem 0;
}

.gq-passenger-type__container {
	flex-basis: 30%;
}

/* .passenger-type__header {
	font-family: 'Poppins';
	font-size: 1.125rem;
	font-weight: 700;
	line-height: 18px;
	text-align: left;
} */

.passenger-age-group__sub-header {
	font-family: 'Poppins';
	font-size: 0.8rem;
	font-weight: 500;
	line-height: 24px;
	text-align: left;
}

.passenger-type__input {
	background: #f0f0f0;
	width: 100px;
	height: 30px;
	border-radius: 10px;
	border: 1px solid #efefef;
	margin: 0.5rem 0;
	text-align: center;
}

.ticket-input-container {
	display: flex;
	margin: 0.5rem 0;
}

.ticket__form-header {
	font-family: 'Poppins';
	font-size: 1.25rem;
	font-weight: 700;
	margin: 0.2rem 0;
	color: #e94e1b;
}

/* Flight class section */
.flight-classes__selector {
	height: 40px;
	width: 250px;
	border-radius: 10px;
	padding: auto;
	background: #f0f0f0 url("data:image/svg+xml,<svg height='10px' width='10px' viewBox='0 0 16 16' fill='%23000000' xmlns='http://www.w3.org/2000/svg'><path d='M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z'/></svg>") no-repeat;
	background-position: calc(100% - 0.75rem) center !important;
	-moz-appearance: none !important;
	-webkit-appearance: none !important;
	appearance: none !important;
}

.flight-classes__selector-option {
	font-family: 'Poppins';
	font-size: 1rem;
	color: #000;
}

@media screen and (max-width: 1200px) {
	.gq-form-container {
		width: initial;
		padding-left: 0;
	}
	.gq-message__box {
		width: 97%;
	}
	.passenger-type__header {
		font-size: 1rem;
	}
	.passenger-age-group__sub-header {
		font-size: 0.75rem;
	}
	.passenger-type__input {
		margin: 0.1rem 0;
		width: 80px;
	}
	.gq-passengers__header {
		margin: 10px 0 !important;
	}
	.gq-dates__header {
		margin: 15px 0 !important;
	}
}

@media screen and (max-width: 600px) {
	.passenger-type__header {
		font-size: 0.75rem;
		margin-bottom: 5px;
	}
	.gq-form-container {
		margin: 0 ;
	}
	.bottom-input__container {
		margin-top: 1.25rem;
	}
	.gq-section-2 {
		margin-bottom: 0.5rem;
	}
	.passengers__header {
		font-size: 1rem;
	}
	.dates__header {
		margin: 0;
		margin-top: 0.5rem;
		font-size: 1rem;
	}
	.gq-message__box {
		width: 98%;
	}
	.popup__form-header {
		margin: 0.1rem 0;
	}
	.popup-input-container {
		margin: 0.2rem 0;
	}
	.flight-classes__selector {
		height: 36px;
	}

	.ticket__form-header {
		font-size: 1rem;
	}

}
/* Wanted */
